<template>
  <div class="quali-form fill-height" v-if="chromeData">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter une fiche Quali</h3>

      <form ref="formel" @submit.prevent="onSubmit" method="POST">
        <div class="mb-s">
          <h5 class="mb-xxs">Nom du Quali *</h5>
          <FormInput name="title" :required="true" />
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Description *</h5>
          <textarea
            name="text"
            placeholder="max 450 caractères espace compris"
            maxlength="450"
            required
          ></textarea>
        </label>

        <div class="mb-s">
          <h5 class="mb-xxs">Adjectifs *</h5>

          <div
            class="select-custom-wrapper"
            :id="`scw-${index}`"
            v-for="(item, index) in adjectives"
            :key="`adjective-${index}`"
          >
            <SelectCustom
              class="mb-xxs"
              name="adjective[]"
              placeholder="Choisissez un adjectif"
              :data="chromeData.lists.adjectives"
            />

            <a class="remove" @click.prevent="removeAdjectives(index)">
              <SvgSprite symbol="ui-close" size="16" />
            </a>
          </div>

          <a
            class="add"
            @click.prevent="addAdjectives"
            v-if="adjectives.length < 50"
          >
            <SvgSprite symbol="icons-add" size="16" />
            <span>Ajouter un adjectif</span>
          </a>
        </div>

        <div class="photos m-s">
          <h5 class="mb-xxs">Photo</h5>
          <Upload
            ref="uploadPhotos"
            name="photos[]"
            icon="image"
            :max="3"
            :options="{
              allowFileTypeValidation: true,
              acceptedFileTypes: ['image/*'],
              allowFileSizeValidation: true,
              maxFileSize: '5MB',
            }"
          />
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ta fiche Quali est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ton Quali a bien été envoyé. Après vérification, il sera publié et
          disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { create } from '@/guides/endpoints/quali'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    SelectCustom,
    Upload,
  },
  setup() {
    const chromeData = ref()
    const showAlert = ref(false)
    const uploadPhotos = ref()
    const adjectives = ref<Array<string>>([''])

    const addAdjectives = () => {
      adjectives.value.length += 1
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removeAdjectives = (index: any) => {
      const adjective = document.querySelector(`#scw-${index}`)

      adjective?.remove()
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    chrome().then(r => {
      chromeData.value = r.data
    })

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      const photos = uploadPhotos.value.getFiles()
      data.delete('photos[]')

      if (photos.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      adjectives,
      addAdjectives,
      removeAdjectives,
    }
  },
})
</script>

<style lang="scss" scoped>
.quali-form {
  @include form-base;

  background: $c-white;

  ::v-deep(.select-custom) {
    background-color: $athens-gray;
    border-color: $athens-gray;
  }
}

.select-custom-wrapper {
  display: flex;
  align-items: center;

  ::v-deep(.select-custom) {
    flex: 1;
  }

  .remove {
    padding: 1rem;
    width: 4rem;
    cursor: pointer;
  }

  &:first-of-type {
    padding-right: 4rem;

    .remove {
      display: none;
    }
  }
}
</style>
