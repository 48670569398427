import api from '@/guides/api'

export const single = (slug: string | string[]) => {
  return api.get(`/qualis/${slug}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const create = (data: any) => {
  return api.post('/qualis', data)
}
